<template>
  <div class="categories" id="select-category">
    <b-container fluid="xxl">
      <b-row class="mt-3">
        <b-col v-if="categories.length">
          <Select2 :options="categoriesOption" @select="selectCategory($event)" :settings="{dropdownParent: '#select-category'}" />
          <!-- <ul v-if="categories.length" class="results-ul">
            <li v-for="category in categories" :key="category.id">
              <a href="#" @click="selectCategory($event, category)">{{ category.concept }}, {{ category.code }}</a>
            </li>
          </ul> -->
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import Select2 from 'v-select2-component'

  export default {
    name: 'CategorySelect',
    props: ['exclude', 'cleanOnSelect'],
    components: {
      Select2
    },
    mounted() {
      this.$store.state.category.categories = []
      this.$store.dispatch('loadCategories')
    },
    computed: {
      ...mapState({
        categories: state => state.category.categories
      }),
      categoriesOption(){
        return this.categories.map(category => {
          return {
            id: category.id,
            text: `${category.concept}, ${category.fullCode}`
          }
        }).sort((a, b) => (a.text > b.text) ? 1 : -1)
      }
    },
    methods: {
      selectCategory(event){
        let category = null
        for (let _category of this.categories){
          if (_category.id == event.id){
            category = _category
            break
          }
        }
        if (this.cleanOnSelect){
          this.categories.splice(this.categories.indexOf(category), 1)
        }
        this.$emit('select', category)
      }
    }
  }
</script>
