<template>
  <div>
    <b-form-group
      id="definition-group"
      label="Definition:"
      label-for="definition"
    >
      <b-form-textarea
        id="definition"
        placeholder="Definition"
        v-model="model.definition"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>

    <b-form-group
      id="paradefinition-group"
      label="Paradefinition:"
      label-for="paradefinition"
    >
      <b-form-textarea
        id="paradefinition"
        placeholder="Paradefinition"
        v-model="model.paradefinition"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>

    <b-form-group
      id="designation-group"
      label="Designation:"
      label-for="designation"
    >
      <b-form-textarea
        id="designation"
        placeholder="Designation"
        v-model="model.designation"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>

    <b-form-group
      id="categories-group"
      label="Categories:"
      label-for="categories"
    >
      <ul>
        <li v-for="category in model.categories" :key="category.id">
          {{ category.concept }}, {{ category.fullCode }}
        </li>
      </ul>
    </b-form-group>

    <h6 v-if="config.editable" class="mt-4">Add new category</h6>
    <CategorySelect v-if="config.editable" 
      @select="selectCategory"
      :cleanOnSelect='true'
      :exclude="model.categories.map(category => category.id)"
    />
    <!-- <b-button type="submit" variant="primary">Save lemma</b-button> -->
  </div>
</template>

<script>
  import CategorySelect from '@/components/CategorySelect.vue'
  import HelpStore from '@/store/helpstore.js'
  import config from '@/config.js'

  export default {
    name: 'SenseNewEdit',
    props: ['sense'],
    data(){
      let _model = (this.sense ? this.sense : {
        id: null,
        definition: '',
        paradefinition: '',
        designation: '',
        categories: [],
        config: config
      })
      HelpStore.item = _model
      return {
        model: _model
      }
    },
    components: {
      CategorySelect
    },
    mounted(){
      HelpStore.item = this.model
    },
    methods: {
      selectCategory(category){
        this.model.categories.push(category)
      }
    }
  }
</script>
