<template>
  <div>

    <b-container fluid="xxl" class="mt-2">
      <b-row class="mt-4">
        <b-col cols="4" class="p-0">
          <PageTitle v-bind:title="'Senses'" />
        </b-col>
        <b-col cols="8" class="text-right" v-if="!config.editable">
          <DictionarySelectPI class="mb-3" />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <div v-if="$store.state.user.settings.isDictionaryFiltered && config.editable" class="mb-3 text-orange">
            <b-icon icon="exclamation-circle-fill" aria-hidden="true"></b-icon>
            Entries in this table are filtered by personal preference for dictionary set in user settings
            ({{ $store.getters.getDictionariesByIds($store.state.user.settings.dictionaries).map(dictionary => dictionary.acronym).join(', ') }})
          </div>
          <b-button v-if="config.editable" variant="primary" size="sm" :disabled="!$store.getters.checkRole('legam.sem.sense.add')" class="float-right" v-b-modal.modal-new-sense>
            <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
            Add new sense
          </b-button>
          <b-modal v-if="config.editable" id="modal-new-sense" title="New sense" ok-title="Save" @ok="saveSense" size="lg">
            <SenseNewEdit />
          </b-modal>
          <!-- <b-modal id="modal-edit-sense" title="Edit sense" ok-title="Save" @ok="saveSense" size="lg">
            <SenseNewEdit :sense="sense" />
          </b-modal> -->

          <LegamDataTables v-if="config.editable" v-bind="$data" @click="editSense" :additionalData="tableAdditionalData" />
          <LegamDataTablesClientSide v-else v-bind="$data" @click="openDetails" ref="PITable" :additionalDataFilter="tableAdditionalDataFilter" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
  * >>> .tdAction {
    width: 100px;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import tdColSafeHTML from '@/components/tdColSafeHTML'
  import tdColAction from '@/components/tdColAction'
  import PageTitle from '@/components/PageTitle.vue'
  import tdColUUID from '@/components/tdColUUID.vue'
  import DictionarySelectPI from '@/components/Dictionary/DictionarySelectPI.vue'
  import LegamDataTables from '@/components/LegamDataTables.vue'
  import LegamDataTablesClientSide from '@/components/LegamDataTablesClientSide.vue'
  import SenseNewEdit from '@/components/SenseNewEdit.vue'
  import HelpStore from '@/store/helpstore.js'
  import config from '@/config.js'

  export default {
    name: 'SenseList',
    data(){
      return {
        dataColumnNames: ["id", "definition", "dictionaryAcronym", "fullCode"],
        columnsPI: [
          { title: this.$t('Definition'), field: 'definition', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          { title: this.$t('Dictionary'), field: 'dictionaryAcronym', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          { title: 'Category', field: 'fullCode', sortable: true, tdComp: tdColSafeHTML, searchable: true }
        ],
        columns: [
          { title: 'ID', field: 'id', sortable: true, tdComp: tdColUUID, searchable: true },
          { title: 'Definition', field: 'definition', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          // { title: 'Paradefinition', field: 'paradefinition', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          // { title: 'Designation', field: 'designation', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          { title: 'Dictionary', field: 'dictionaryAcronym', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          { title: 'Category', field: 'fullCode', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          { title: 'Details', field: 'action', tdComp: tdColAction, thClass: 'tdAction',
            transformData: function(row){
              let retVal = [
                // {text: null, icon: 'pencil-fill', dataId: row.id},
                // {text: 'Details', icon: 'list-ul', modalTitle: 'Sense details: ' + row.lemmaForm, id: 'details-' + row.id},
                {text: null, icon: 'list-ul', id: row.id, to: {name: 'Sense', params: {id: row.id}}}
              ]
              // if (row.articleId == null){
              //   retVal.push(
              //     {text: 'Add to article', icon: 'plus-circle-fill', modalTitle: 'Add to article', id: row.id, to: {name: 'AddToArticle', id: row.id}}
              //   )
              // }
              return retVal
            }
          }
        ],
        url: '/senses/datatables',
        key: 1,
        searchableByColumn: true,
        config: config,
      }
    },
    components: {
      PageTitle,
      LegamDataTables,
      LegamDataTablesClientSide,
      SenseNewEdit,
      DictionarySelectPI,
    },
    computed: {
      ...mapState({
        sense: state => state.sense.sense,
        userSettings: state => state.user.settings,
        userSettingsPI: state => state.user.settingsPI,
      }),
      tableAdditionalData(){
        return {
          'dictionaries': this.userSettings.dictionaries
        }
      }
    },
    watch: {
      "sense"(){
        this.$forceUpdate()
        this.$bvModal.show('modal-edit-sense')
      },
      "userSettings"(){
        this.key++
      },
      userSettingsPI: {
        deep: true,
        handler() {
          if (this.$refs.PITable.table) {
            this.$refs.PITable.sortPageFilter()
          }
        }
      },
    },
    methods: {
      tableAdditionalDataFilter(data) {
        if (this.userSettingsPI.dictionaries.length) {
          data = data.filter(row => this.userSettingsPI.dictionaries.includes(row.dictionaryAcronym))
        }
        return data
      },
      editSense(senseId){
        this.$store.dispatch('loadSense', {
            senseId: senseId
          })
      },
      openDetails(data) {
        router.push(`/sense/${data.id}`)
      },
      saveSense(){
        this.$store.dispatch('createSense', HelpStore.item).then((response) => {
          router.push(`/sense/${response.data.id}`)
        })
      }
    }
  }
</script>
